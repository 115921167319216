import React from "react";
import LINK_SCHEME from "./footer-links.utils";
import { triggerTealiumEvent } from "../../utils/tealiumUtils";
import { Link } from "gatsby";
import ExternalLink from "../external-link/external-link.component";
import { useLocation } from "@reach/router";

const FooterLinks = ({ links = LINK_SCHEME }) => {
  const cookieNoticeUrl = "https://www.hsbc.co.uk/cookie-notice";
  const location = useLocation();

  const handleClick = (isExternal, title) => {
    if (typeof window === "undefined" || !window.utag) return;

    const lastSegment = location.href.replace(/\/$/, "").split("/").pop();
    const currentPath = location.href.includes("essential-reading")
      ? "essential-reading"
      : location.href.includes("legal-information")
      ? "legal-information"
      : location.href.includes("step-by-step-guide-to-hsbc-mortgages")
      ? "step-by-step-guide-to-hsbc-mortgages"
      : lastSegment || "home";
    const cookiePage = isExternal ? cookieNoticeUrl.split("/").pop() : title;

    const eventDetails = {
      home: {
        pageType: "homepage",
        pageSubCategory: "Home",
        rawDataLayer: "10451v3",
        eventContent: `footer | home page : ${cookiePage}`,
      },
      products: {
        pageType: "product information",
        pageName: "products",
        pageSubCategory: "products",
        rawDataLayer: "10451v9",
        eventContent: `footer | products : ${cookiePage}`,
      },
      criteria: {
        pageType: "product information",
        pageName: "criteria",
        pageSubCategory: "criteria",
        rawDataLayer: "10451v13",
        eventContent: `footer | criteria : ${cookiePage}`,
      },
      calculator: {
        pageType: "product information",
        pageName: "calculator",
        pageSubCategory: "calculator",
        rawDataLayer: "10451v19",
        eventContent: `footer | calculators : ${cookiePage}`,
      },
      newsroom: {
        pageType: "product information",
        pageName: "newsroom",
        pageSubCategory: "newsroom",
        rawDataLayer: "10451v23",
        eventContent: `footer | news : ${cookiePage}`,
      },
      resources: {
        pageType: "product information",
        pageName: "resources",
        pageSubCategory: "resources",
        rawDataLayer: "10451v50",
        eventContent: `footer | resources : ${cookiePage}`,
      },
      "our-latest-service-levels": {
        pageType: "product information",
        pageName: "our latest service levels",
        pageSubCategory: "our latest service levels",
        rawDataLayer: "10451v70",
        eventContent: `footer | our latest service levels : ${cookiePage}`,
      },
      complaints: {
        pageType: "product information",
        pageName: "complaints",
        pageSubCategory: "complaints",
        rawDataLayer: "10451v40",
        eventContent: `footer | complaints : ${cookiePage}`,
      },
      awards: {
        pageType: "product information",
        pageName: "awards",
        pageSubCategory: "awards",
        rawDataLayer: "10451v63",
        eventContent: `footer | awards : ${cookiePage}`,
      },
      "packaging-requirements": {
        pageType: "product information",
        pageName: "packaging requirements",
        pageSubCategory: "packaging requirements",
        rawDataLayer: "10451v74",
        eventContent: `footer | packaging requirements : ${cookiePage}`,
      },
      "broker-support": {
        pageType: "product information",
        pageName: "broker support",
        pageSubCategory: "broker support",
        rawDataLayer: "10451v32",
        eventContent: `footer | broker support : ${cookiePage}`,
      },
      "energy-performance-certificate": {
        pageType: "product information",
        pageName: "energy performance certificate",
        pageSubCategory: "energy performance certificate",
        rawDataLayer: "10451v89",
        eventContent: `footer | energy performance certificate : ${cookiePage}`,
      },
      "placing-business": {
        pageType: "product information",
        pageName: "placing business",
        pageSubCategory: "placing business",
        rawDataLayer: "10451v54",
        eventContent: `footer | placing business : ${cookiePage}`,
      },
      "essential-reading": {
        pageType: "product information",
        pageName: `newsroom:essential reading:${lastSegment}`,
        pageSubCategory: "newsroom",
        rawDataLayer: "10451v28",
        eventContent: `footer | ${lastSegment} : ${cookiePage}`,
      },
      "legal-information": {
        pageType: "product information",
        pageName: "legal information",
        pageSubCategory: "legal information",
        rawDataLayer: "10451v59",
        eventContent: `footer | legal information : ${cookiePage}`,
      },
      "frequently-asked-questions": {
        pageType: "product information",
        pageName: "frequently asked questions",
        pageSubCategory: "frequently asked questions",
        rawDataLayer: "10451v66",
        eventContent: `footer | frequently asked questions : ${cookiePage}`,
      },
      green: {
        pageType: "product information",
        pageName: "green",
        pageSubCategory: "green",
        rawDataLayer: "10451v85",
        eventContent: `footer | green : ${cookiePage}`,
      },
      "step-by-step-guide-to-hsbc-mortgages": {
        pageType: "product information",
        pageName: "step by step guide to hsbc mortgages",
        pageSubCategory: "step by step guide to hsbc mortgages",
        rawDataLayer: "10451v80",
        eventContent: `footer | step by step guide to hsbc mortgages : ${cookiePage}`,
      },
    };

    const config = eventDetails[currentPath];
    if (!config) {
      return;
    }

    const { eventContent, pageType, pageName = "", pageSubCategory, rawDataLayer } = config;

    triggerTealiumEvent({
      tealiumEvent: "link",
      pageUrl: location.href,
      eventCategory: "content",
      eventAction: "onsite",
      eventContent,
      pageType,
      pageName,
      pageSubCategory,
      rawDataLayer,
    });
  };

  return (
    <ul className="footer-links">
      {links.map(({ title, href }, index) => (
        <li key={index}>
          {href.includes(cookieNoticeUrl) ? (
            <ExternalLink to={href} onClick={() => handleClick(true, title)}>
              {title}
            </ExternalLink>
          ) : (
            <Link to={href} onClick={() => handleClick(false, title)}>
              {title}
            </Link>
          )}
        </li>
      ))}
    </ul>
  );
};

export default FooterLinks;
